import { graphql, useStaticQuery } from 'gatsby';
import { CategoryNode } from '../types';
import { buildNavItems } from './categories';

type Data = {
  allMarkdownRemark: {
    nodes: CategoryNode[]
  }
}

export const navQuery = graphql`
  query NavtQuery {
    allMarkdownRemark(filter: {fields: {itemType: {eq: "categories"}}}) {
      nodes {
        frontmatter {
          label
          sub_label
        }
        fields {
          slug
          used
        }
      }
    }
  }
`;

const useNavItems = () => {
  const { allMarkdownRemark } = useStaticQuery<Data>(navQuery);

  return buildNavItems(allMarkdownRemark.nodes);
};

export default useNavItems;
