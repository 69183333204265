import {
  CategoryNode, NavChild, NavItem, NavParent,
} from '../types';

export const getLinkCategoriesOnly = (categories: NavItem[]) => categories
  .flatMap((item) => (item.children || item));

const getPathOrder = (path: string) => {
  const regex = /categories\/([a-zA-Z0-9-_]*)\/([a-zA-Z0-9-_]*)\/*/;

  const match = path.match(regex);
  if (match) {
    const [, ...parentAndMaybeChild] = match;

    return parentAndMaybeChild.filter(Boolean);
  }

  return [];
};

export const buildNavItems = (nodes: CategoryNode[]) => nodes.reduce<NavItem[]>((items, node) => {
  if (node.fields.used) {
    const [parent, child] = getPathOrder(node.fields.slug);

    if (!child) {
      const formattedChild: NavChild = {
        label: node.frontmatter.label,
        href: node.fields.slug,
      };

      items.push(formattedChild);
    } else {
      const parentLabel = parent[0].toUpperCase() + parent.slice(1);
      const parentIndex = items.findIndex((category) => parentLabel === category.label);

      const formattedChild: NavChild = {
        label: node.frontmatter.label,
        href: node.fields.slug,
        subLabel: node.frontmatter.sub_label,
      };

      if (parentIndex >= 0) {
        items[parentIndex].children?.push(formattedChild);
      } else {
        const formattedParent: NavParent = {
          label: parentLabel,
          children: [formattedChild],
        };
        items.push(formattedParent);
      }
    }
  }

  return items;
}, []);
