import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Button, useBreakpointValue } from '@chakra-ui/react';

type Props = {
  text?: string;
  mode?: 'nav' | 'body';
}

const EnquireButton: React.FC<Props> = ({ text = 'Enquire', mode = 'body' }) => {
  const variant = useBreakpointValue({ base: 'ghost', md: 'solid' });
  return (
    <Button
      as={GatsbyLink}
      to="/contact/"
      colorScheme="pink"
      fontWeight="700"
      size={mode === 'body' ? 'lg' : 'sm'}
      variant={mode === 'body' ? 'solid' : variant}
    >
      {text}
    </Button>
  );
};

export default EnquireButton;
