import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import {
  Box,
  Container,
  Stack,
  Text,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';

const links = [['Home', '/'], ['About', '/about/'], ['Contact', '/contact/']] as const;

const Footer = () => (
  <Box
    bg={useColorModeValue('gray.50', 'gray.900')}
    color={useColorModeValue('gray.600', 'white')}
    pb={16}
  >
    <Container
      as={Stack}
      maxW="6xl"
      py={8}
      direction={{ base: 'column', md: 'row' }}
      spacing={4}
      justify={{ base: 'center', md: 'space-between' }}
      align={{ base: 'center', md: 'flex-start' }}
    >
      <Stack direction="row" spacing={6}>
        {links.map(([label, href]) => (
          <Link
            as={GatsbyLink}
            key={label}
            to={href}
            fontSize="sm"
            fontWeight={500}
            color={useColorModeValue('gray.600', 'gray.200')}
            _hover={{
              textDecoration: 'none',
              color: useColorModeValue('gray.800', 'white'),
            }}
          >
            {label}
          </Link>
        ))}

      </Stack>
      <Stack
        direction="column"
        align={{ base: 'center', md: 'flex-end' }}
        spacing={8}
      >

        <Text fontSize="sm">
          ©
          {new Date().getFullYear()}
          {' '}
          Libby Hackett. All rights reserved
        </Text>
        <Link
          fontSize="sm"
          href="https://zej.com.au"
          color={useColorModeValue('pink.500', 'pink.100')}
          isExternal
        >
          Website by
          {' '}
          <strong>zej.</strong>
        </Link>
      </Stack>

    </Container>
  </Box>
);

export default Footer;
