import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import {
  Box,
  Flex,
  Link,
  Text,
  Stack,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { NavChild } from '../../types';

const DesktopSubNav = ({ label, href, subLabel }: NavChild) => (
  <Link
    as={GatsbyLink}
    to={href}
    role="group"
    display="block"
    p={2}
    rounded="sm"
    _hover={{ bg: useColorModeValue('pink.50', 'gray.800') }}
  >
    <Stack direction="row" align="center">
      <Box>
        <Text
          transition="all .3s ease"
          _groupHover={{ color: useColorModeValue('pink.500', 'pink.200') }}
          fontWeight={500}
        >
          {label}
        </Text>
        <Text fontSize="sm">{subLabel}</Text>
      </Box>
      <Flex
        transition="all .3s ease"
        transform="translateX(-10px)"
        opacity={0}
        _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
        justify="flex-end"
        align="center"
        flex={1}
      >
        <Icon
          color={useColorModeValue('pink.500', 'pink.200')}
          w={5}
          h={5}
          as={ChevronRightIcon}
        />
      </Flex>
    </Stack>
  </Link>
);

export default DesktopSubNav;
