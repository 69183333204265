import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import {
  Box,
  Flex,
  Link,
  Button,
  IconButton,
  Stack,
  Collapse,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import DesktopNav from './desktop-nav';
import MobileNav from './mobile-nav';
import { NavItem } from '../../types';
import EnquireButton from '../enquire-button';

type Props = {
  items: NavItem[]
}

const Navigation = ({ items = [] }: Props) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <Flex
        bg={useColorModeValue('white', 'gray.900')}
        color={useColorModeValue('gray.600', 'white')}
        minH="60px"
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle="solid"
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align="center"
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant="ghost"
            aria-label="Toggle Navigation"
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Link
            as={GatsbyLink}
            to="/"
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontFamily="heading"
            fontWeight={800}
            color={useColorModeValue('gray.800', 'white')}
            _hover={{
              textDecoration: 'none',
              color: useColorModeValue('pink.500', 'pink.200'),
            }}
            alignSelf="center"
          >
            Libby Hackett
          </Link>

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav navItems={items} />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify="flex-end"
          direction="row"
          spacing={6}
          alignItems="center"
        >
          <Button
            as={GatsbyLink}
            display={{ base: 'none', md: 'inline-flex' }}
            variant="link"
            p={2}
            fontSize="sm"
            fontWeight={500}
            _hover={{
              textDecoration: 'none',
            }}
            to="/about/"
          >
            About
          </Button>
          <EnquireButton text="Contact" mode="nav" />
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav navItems={items} />
      </Collapse>
    </Box>
  );
};

export default Navigation;
