import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Helmet from 'react-helmet';
import {
  Box,
  Flex,
  Container,
} from '@chakra-ui/react';

import Navigation from './navigation';
import Footer from './footer';
import useNavItems from '../util/use-nav-items';

export const layoutQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`;

type Props = {
  titleOverride?: string;
  descriptionOverride?: string;
  constrainWidth?: boolean;
}

type Data = {
  site: {
    siteMetadata: {
      title: string;
      author: string;
      description: string;
    }
  }
}

const Layout: React.FC<Props> = ({
  children,
  titleOverride, descriptionOverride, constrainWidth = true,
}) => {
  const navItems = useNavItems();
  const { site } = useStaticQuery<Data>(layoutQuery);
  const { title, author, description } = site.siteMetadata;

  return (
    <>
      <Helmet>
        <title>{titleOverride || title}</title>
        <meta name="description" content={descriptionOverride || description} />
        <meta name="author" content={author} />
      </Helmet>

      <Flex
        direction={{ base: 'column' }}
        align={{ base: 'center' }}
        height="100vh"
        width="100%"
      >
        <Box width="100%">
          <Navigation items={navItems} />
        </Box>
        <Container flex="1" maxW={constrainWidth ? '5xl' : '100vw'} px={0}>
          {children}
        </Container>
        <Box justifySelf="flex-end" width="100%">
          <Footer />
        </Box>
      </Flex>

    </>
  );
};

export default Layout;
