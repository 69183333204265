import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import {
  Flex,
  Stack,
  Collapse,
  Text,
  Icon,
  useColorModeValue,
  useDisclosure,
  Button,
  Spacer,
} from '@chakra-ui/react';
import {
  ChevronDownIcon,
} from '@chakra-ui/icons';

import type { NavItem } from '../../types';

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack
      spacing={4}
      onClick={children && onToggle}
    >
      <Flex
        as={children ? Button : GatsbyLink}
        to={children ? undefined : href}
        // @ts-expect-error not inferring this only renders for button
        variant={children ? 'link' : undefined}
        justify="space-between"
        align="center"
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Text
          py={2}
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}
        >
          {label}
        </Text>
        {children
            && (
            <Icon
              as={ChevronDownIcon}
              transition="all .25s ease-in-out"
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
            )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle="solid"
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align="start"
        >
          {children
            && children.map((child) => (
              <Button
                as={child.href ? GatsbyLink : undefined}
                to={child.href || '#'}
                variant="link"
                key={child.label}
                py={2}
              >
                {child.label}
              </Button>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const MobileNav = ({ navItems }: { navItems: NavItem[] }) => (
  <Stack
    bg={useColorModeValue('white', 'gray.900')}
    p={4}
    display={{ md: 'none' }}
    boxShadow="lg"
  >
    {navItems.map((navItem) => (
      <MobileNavItem key={navItem.label} {...navItem} />
    ))}
    <Spacer
      borderTop="1px solid"
      borderTopColor={useColorModeValue('gray.200', 'gray.700')}
    />
    <MobileNavItem href="/about/" label="About" />
  </Stack>
);

export default MobileNav;
